var MultipleActions = {};
MultipleActions.instances = [];

MultipleActions.addInstance = function(selector){
    var newInstanceId = MultipleActions.instances.length;
    var newInstance = {};
    newInstance.selector = selector;
    newInstance.element = $(newInstance.selector);
    newInstance.tableSelector = $(newInstance.selector).data('table');
    newInstance.tableElement = $(newInstance.tableSelector);
    newInstance.checkboxValues = [];
    MultipleActions.instances[newInstanceId] = newInstance;
    MultipleActions.init(MultipleActions.instances[newInstanceId]);
};

MultipleActions.init = function(multipleActionInstance){
    MultipleActions.addCheckboxContainer(multipleActionInstance);
    MultipleActions.updateCheckboxValues(multipleActionInstance);
    MultipleActions.listenForCheckAll(multipleActionInstance);
    MultipleActions.listenForCheck(multipleActionInstance);
    //Listen for checks to add array of selected to form MultipleActions.updateSelectedData()...
};

MultipleActions.addCheckboxContainer = function(multipleActionInstance){
    $('<div>',{class: 'checkbox-container',style: 'display:none;'}).appendTo(multipleActionInstance.element);
};

MultipleActions.updateCheckboxValues = function(multipleActionInstance){
    multipleActionInstance.checkboxValues = [];
    $("[name='multiple-select']:checked",multipleActionInstance.tableElement).each(function() {
        multipleActionInstance.checkboxValues.push($(this).val());
    });
    MultipleActions.updateCheckboxContainer(multipleActionInstance);
};

MultipleActions.updateCheckboxContainer = function(multipleActionInstance){
    console.log(multipleActionInstance.checkboxValues);
    $(".checkbox-container",multipleActionInstance.element).empty();
    $.each(multipleActionInstance.checkboxValues, function( index, value ) {
        $(".checkbox-container",multipleActionInstance.element).append('<input type="checkbox" name="id[]" checked value="'+value+'" />');
    });

};

MultipleActions.listenForCheckAll = function(multipleActionInstance){
    $("[name='multiple-select-toggle']",multipleActionInstance.tableElement).change(function () {
        $("[name='multiple-select']",multipleActionInstance.tableElement).prop('checked', $(this).prop("checked"));
        MultipleActions.updateCheckboxValues(multipleActionInstance);
    });
};
MultipleActions.listenForCheck = function(multipleActionInstance){
    $("[name='multiple-select']",multipleActionInstance.tableElement).change(function () {
        MultipleActions.updateCheckboxValues(multipleActionInstance);
    });
};

MultipleActions.listenForCreateSubmit = function(){
    /*
    $('#job-files-create-modal .btn-submit',MultipleActions.selector).click(function(event){
        var formData = new FormData($('#job-files-create-modal form',MultipleActions.selector)[0]);
        var formAction = $('#job-files-create-modal form',MultipleActions.selector).attr('action');
        $('#job-files-create-modal',MultipleActions.selector).modal('hide');
        $('.btn-upload',MultipleActions.selector).html('<span class="text-danger"><i class="fa fa-clock-o"></i></span>');
        $.ajax({
            url: formAction,
            type: 'POST',
            success: function(data){
                console.log(data);
                MultipleActions.getData();
                $('.btn-upload',MultipleActions.selector).html('Upload');
            },
            data: formData,
            cache: false,
            contentType: false,
            processData: false
        });
    });
    */
};